// use global jquery which already setup datatables
var $ = window.$;

window.ttu_init_single_select2 = (select, option = null, ajax_data = null, disabled = false) => {
  var $select = $(select);

  var default_option = {
    dropdownAutoWidth: true,
    multiple: false,
    allowClear: true,
    theme: 'bootstrap',
    disabled: disabled,
    placeholder: '',
  };

  var placeholder = $select.data('placeholder');
  if (placeholder) {
    default_option.placeholder = placeholder;
  }
  var url = $select.data('url');
  var pageLength = $select.data('page-length');

  // inject option.ajax_data if any
  if (option !== null && typeof option === 'object' && option.ajax_data && ajax_data == null) {
    ajax_data = option.ajax_data;
  }

  if (url) {
    default_option.ajax = {
      url: url,
      beforeSend: (request) => {
        request.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      dataType: 'json',
      delay: 250,
      data: function(params) {
        result = {
          query: params.term || '',
          pageLength: pageLength || 10,
          pageOffset: params.page || 0,
        };

        if (ajax_data === null) {
          // nothing to do here...
        } else if(typeof ajax_data === 'function') {
          var additional_data = ajax_data();
          if (additional_data !== null && typeof additional_data === 'object') {
            Object.assign(result, additional_data);
          }
        } else if (typeof ajax_data === 'object') {
          Object.assign(result, ajax_data);
        }
        return result;
      },
    };
  }

  if (option === null) {
    // nothing to do here...
  } else if (typeof option === 'function') {
    // apply option function
    default_option = option(default_option);
  } else if (typeof option === 'object') {
    // TODO: do more elaborate merge?
    Object.assign(default_option, option);
    delete default_option.ajax_data;
  }

  $select.select2(default_option);
};

window.ttu_init_select2 = (selects, option = null) => {
  $(selects).each((i, e) => { ttu_init_single_select2(e, option); });
};
