// use global jquery which already setup datatables
var $ = window.$;

window.ttu_bootbox_handle_ajax_error = function(xhr) {
  var errorMsg = 'เนื่องจาก เกิดข้อผิดพลาดขึ้นในระบบ';
  var statusCode = '-';
  var callback = function(){};
  if (typeof xhr === 'string') {
    errorMsg = xhr;
  } else {
    statusCode = xhr.status;
    try {
      var json = JSON.parse(xhr.responseText);
      if (json.error) {
        errorMsg = 'เกิดข้อผิดพลาด ' + json.error;
      } else if (json.errors && json.errors.constructor === Array) {
        errorMsg = '<p>เกิดข้อผิดพลาด ดังนี้</p> <ul><li>' + json.errors.join('</li><li>') + '</li></ul>';
      }
      // redirect after alert if url is available
      if (json.url) {
        callback = function() {
          window.location.assign(json.url);
        };
      }
    } catch(e) {

    }
  }
  bootbox.alert({
    title: 'ขออภัย ไม่สามารถทำรายการนี้ได้ [' + statusCode + ']',
    message: errorMsg,
    locale: 'th',
    callback: callback,
  });
};

window.ttu_show_bootbox_confirm_delete = (label, remove_url = null, ajax_success_callback = null, option = null) => {
  var default_option = {
    title: 'ยืนยันการลบ',
    message: 'ต้องการลบ ['+label+'] หรือไม่?',
    locale: 'th',
  };

  if (remove_url) {
    var default_ajax_option = {
      url: remove_url,
      method: 'DELETE',
      dataType: 'json',
      beforeSend: (request) => {
        request.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      error: ttu_bootbox_handle_ajax_error,
    };

    if (ajax_success_callback && typeof ajax_success_callback === 'function') {
      default_ajax_option.success = ajax_success_callback;
    }

    default_option.callback = (result) => {
      if (result) {
        $.ajax(default_ajax_option);
      }
    };
  }

  if (option === null) {
    // nothing to do here...
  } else if (typeof option === 'function') {
    // apply option function
    default_option = option(default_option);
  } else if (typeof option === 'object') {
    // TODO: do more elaborate merge?
    Object.assign(default_option, option);
  }

  bootbox.confirm(default_option);
};
