# default options for dataTable
# 
# !!!!!!!!!!!     THIS REQUIRE window.$        !!!!!!!!!!
#
window.$.extend window.$.fn.dataTable.defaults,
  dom: "<'row'<'col-sm-12 col-md-6'B><'col-sm-12 col-md-6'f>>" + "<'row'<'col-sm-12'tr>>" + "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
  language: {url: '/datatable.i18n.thai.custom.json'}
  select: true
  colReorder: true
  stateSave: true
  #sadly, seems like the default is not merged when actual options presence
  buttons:
    dom:
      button: { className: 'btn', },
      container: { className: '', },

window.$.extend true, window.$.fn.dataTable.Editor.defaults,
  i18n:
    edit:
      button: "แก้ไข"
      title: "แก้ไข"
      submit: "ยืนยัน"
    create:
      button: "เพิ่ม"
      title: "เพิ่มรายการ"
      submit: "ยืนยัน"
    remove:
      button: "ลบ"
      title: "ลบ"
      submit: "ยืนยัน"
      confirm:
        _: 'ยืนยันการลบรายการที่เลือก จำนวน %d รายการ'
        1: 'ยืนยันการลบรายการที่เลือก จำนวน 1 รายการ'


# start TTU custom
# -- renderer --
ttu = {}
ttu.sum_qpc_renderer = window.$.fn.dataTable.render.number(',','.',4)
ttu.number_format_renderer = window.$.fn.dataTable.render.number(',','.',2)
ttu.number_1digit_renderer = window.$.fn.dataTable.render.number(',','.',1)
ttu.unit_format_renderer = window.$.fn.dataTable.render.number(',','.',0)
ttu.total_calc_renderer = (data, type, row, meta) ->
  value = data.Qty  * (data.Price - data.Discount)
  return window.$.fn.dataTable.render.number(',','.',2).display(value)


ttu.yes_no_renderer_factory = (yes_word = "ใช่", no_word = "ไม่ใช่") ->
  return (data, type, row, meta) ->
    if (data == false || data == 0)
      return "<span class='badge badge-pill badge-danger'>"+no_word+"</span>"
    if (data == true || data == 1)
      return "<span class='badge badge-pill badge-success'>"+yes_word+"</span>"
    return ''

ttu.yes_no_renderer = ttu.yes_no_renderer_factory()
ttu.yes_no_renderer_en = ttu.yes_no_renderer_factory('Yes','No')


ttu.yes_no_flip_renderer = (data, type, row, meta) ->
  if (data == false || data == 0)
    return "<span class='badge badge-pill badge-success'>ใช่</span>"
  if (data == 1)
    return "<span class='badge badge-pill badge-danger'>ไม่ใช่</span>"
  return ''

window.yes_no_render = ttu.yes_no_renderer

ttu.col_with_error_renderer = (data, type, row, meta) ->
  if (row.Finished == true || row.item.TrackStock == 0)
    return row.Qty
  if (row.Qty > row.Stock)
    return "#{row.Qty}<span style='font-size: 0.5rem; color: red; display: block;'>ของไม่พอ มี #{row.Stock}</span>"
  return row.Qty

ttu.date_renderer_en  = $.fn.dataTable.render.moment('YYYY-MM-DDTHH:mm:ss.SSSSZ','DD MMM YYYY', 'en')
ttu.date_renderer  = $.fn.dataTable.render.moment('YYYY-MM-DDTHH:mm:ss.SSSSZ','DD MMM tYYYY', 'th')

# generic lookup renderer
# example...  render: ttu.look_up_renderer_factory(#{ Payment::PAYMENT_TYPE.map.with_index{|x,i| [i,x]}.to_h.to_json.html_safe })
ttu.look_up_renderer_factory = (obj) ->
  renderer = (data,type,row,meta) ->
    if (data?)
      return obj[data] || ''
    return ''
  return renderer

#base_url must be has -123 as a placeholder
ttu.generic_link_renderer_factory = (base_url) ->
  return (data,type,row,meta) ->
    if (!['display'].includes(type))
      return data
    if (data == null)
      return ''
    return '<a href="' + base_url.replace('-123',data) + '">' +data+'</a>'

ttu.saleorder_link_renderer = ttu.generic_link_renderer_factory('/sale_orders/-123/edit')
ttu.expense_link_renderer = ttu.generic_link_renderer_factory('/expenses/-123/edit')

ttu.process_readonly = (value) ->
  $("input[type=checkbox][readonly]").attr('disabled',value)
  $("input[type=radio][readonly]").attr('disabled',value)

ttu.set_header_filter = (table, data_col_name, input_html) ->
  col = table.column(data_col_name+':name')
  th1 = col.header()
  th2 = $(th1).parent().next().find('th').eq($(th1).index())
  input = $(input_html)
  input.attr('data-col-name',data_col_name)
  input.val(col.search())
  input.on 'keyup change', debounce(((e) ->
    searchKey = e.currentTarget.value
    col_name = $(e.currentTarget).data('colName')
    table.column(col_name + ':name').search(searchKey,false).draw()
  ), 500)
  th2.html(input)

ttu.clear_header_filter = (table) ->
  $('th input',table.header().toJQuery()).val('')
  table.search('')
  table.columns().search('').draw()

ttu.init_datetimepicker = (options) ->
  default_options = {
    locale: 'th',
    dayViewHeaderFormat: 'MMMM tYYYY',
  }

  window.$(".td-datepicker").each (index,elem) ->
    format = $(elem).find('input[type=text]').data()['datepickerformat'] || 'DD/MMM/tYYYY'
    default_options.format = format
    default_options.date= $(elem).find('input[type=text]').attr('value')
    window.$(elem).datetimepicker($.extend default_options, options)

    window.$(elem).on 'change.datetimepicker', (e) ->
      window.$(e.target).find('input[type=hidden]').val(e.date.format('YYYY-MM-DD HH:mm'))

#initializer for select2
#this is a copy from ttu_select2_helper.js which should be removed later
ttu.init_single_select2 = (select, option = null, ajax_data = null, disabled = false) ->
  #use jQuery from window which should been initialized with datatable
  $ = window.$
  $select = $(select)

  default_option = {
    dropdownAutoWidth: true,
    width: 'auto',
    multiple: false,
    allowClear: true,
    theme: 'bootstrap',
    disabled: disabled,
    placeholder: '',
  }

  placeholder = $select.data('placeholder')
  if (placeholder)
    default_option.placeholder = placeholder

  url = $select.data('url')
  pageLength = $select.data('page-length')

  #inject option.ajax_data if any
  if (option != null && typeof option == 'object' && option.ajax_data && ajax_data == null)
    ajax_data = option.ajax_data

  if (url)
    default_option.ajax = {
      url: url,
      beforeSend: (request) ->
        request.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      dataType: 'json',
      delay: 250,
      data: (params) ->
        result = {
          query: params.term || '',
          pageLength: pageLength || 10,
          pageOffset: params.page || 0,
        }

        if ajax_data == null
          #nothing to do here...
        else if typeof ajax_data == 'function'
          additional_data = ajax_data()
          if additional_data != null and typeof additional_data == 'object'
            Object.assign result, additional_data
        else if typeof ajax_data == 'object'
          #merge additional data
          Object.assign result, ajax_data

        return result
    }

  if option == null
    # nothing to do here...
  else if typeof option == 'function'
    # apply option function
    default_option = option(default_option)
  else if typeof option == 'object'
    #TODO: do more elaborate merge?
    Object.assign default_option, option
    delete default_option.ajax_data

  $select.select2(default_option)

#initialize multiple select2 with same options
ttu.init_select2 = (selects, option = null, ajax_data = null, disabled = false) ->
  $(selects).each (i, e) ->
    ttu.init_single_select2(e, option, ajax_data, disabled)

#url must be in the form aaa/bbb/-123 where -123 is a placeholder to be replace
#inside the function
ttu.child_row_updater_generator = (url) ->
  return (d,row) ->
    row.child('<div>กำลังอ่านข้อมูล...</div>')
    $.ajax({
      url: url.replace('-123',d.ID || d.id),
      method: 'GET',
      dataType: 'html',
      success: (data) ->
        row.child(data,'child-row')
      error: (xhr) ->
        ttu_bootbox_handle_ajax_error(xhr)
        row.child('ไม่สามารถดูรายการได้ กรุณาลองใหม่')
    })

# this return a function that should be used on  table.on('click','td.detail-control')
# the function returned by this will handle clicking on that cells and update the child of that row using
# [child_row_updater]
ttu.child_row_handler_generator = (table,child_row_updater,shown_icon = null, hidded_icon = null) ->
  return (e) ->
    tr = $(e.target).closest('tr')
    #window.xxx = e.target
    #icon = $(tr).find('td.details-control'+find_option+' i')
    icon = $(e.target).closest('i')
    row = table.row($(e.target).closest('tr'))
    if (row.child.isShown())
      row.child.hide()
      tr.removeClass('shown')
      if icon.hasClass("material-icons")
        #mdi mode
        icon.html('add_circle_outline')
      else
        #font awesome mode
        icon.removeClass('fa-minus-square')
          .addClass('fa-plus-square')
    else
      child_row_updater(row.data(), row)
      row.child.show()
      tr.addClass('shown')
      if icon.hasClass("material-icons")
        #mdi mode
        icon.html('remove_circle_outline')
      else
        #font awesome mode
        icon.removeClass('fa-plus-square')
          .addClass('fa-minus-square')


# datatable footer aggregrate function
#  [table] is the datatable API instance
#  [column_names] is an array of string to be used by table.column(xxx)
#    e.g., ['Qty:name','sum_amount:name']
ttu.footer_aggregator = (table, column_names, reduce_func, reduce_init_value, renderer = ttu.number_format_renderer) ->
  column_names.forEach( (col_name) ->
    #calculate sum
    value = table.column(col_name,{search:'applied'}).data().reduce(reduce_func, reduce_init_value)
    $(table.column(col_name).footer()).html( renderer.display(value) )
  )

ttu.auto_count_footer = (table, column_names) ->
  ttu.footer_aggregator(table,column_names, ( (sum,each) ->
    if (each != null && (typeof each != "string" || each.trim().length > 0))
      return sum + 1
    else
      return sum
  ), 0, ttu.unit_format_renderer)

ttu.auto_count_if_footer = (table, column_names, value) ->
  ttu.footer_aggregator(table,column_names, ( (sum,each) ->
    if (each == value)
      return sum + 1
    else
      return sum
  ), 0, ttu.unit_format_renderer)

#auto summation
#  [table] is the datatable API instance
#  [column_names] is an array of string to be used by table.column(xxx)
#    e.g., ['Qty:name','sum_amount:name']
ttu.auto_sum_footer = (table, column_names, renderer = ttu.number_format_renderer) ->
  ttu.footer_aggregator(table,column_names, ( (sum,each) ->
    sum + (parseFloat(each) || 0)
  ), 0, renderer)

ttu.hide_hq_fields = (table,hide) ->
  table.columns('.hq-only').visible(!hide)

# generate a function to be used in DataTable buttons: [ action: ]
# given "id_name" and "path" with place holder
# the generate function will jump to the url provided by
# the path string with the place holder replaced by data in the id field
#
ttu.rails_path_placeholder = '~_-_~'
ttu.btn_goto_link = (id_name,path,confirmation = false, bootbox_text = {title: 'confirm', message: 'message'}) ->
  if (confirmation)
    return (e, dt, node, config) ->
      bootbox.confirm
        title: bootbox_text.title,
        message: bootbox_text.message,
        locale: 'th',
        centerVertical: true,
        callback: (result) ->
          if (result)
            row = dt.row( {selected: true} )
            if (row.node())
              url = path.replace(ttu.rails_path_placeholder,row.data()[id_name])
              if (url)
                window.location.assign(url)

  else
    return (e, dt, node, config) ->
      row = dt.row( {selected: true} )
      if (row.node())
        url = path.replace(ttu.rails_path_placeholder,row.data()[id_name])
        if (url)
          window.location.assign(url)

# similar to above but use ajax
ttu.btn_ajax = (id_name,path,ajax_param = {method: 'POST', dataType: 'json' }, confirmation = false, bootbox_text = {title: 'confirm', message: ''}) ->
  return (e, dt, node, config) ->
    bootbox.confirm
      title: bootbox_text.title,
      message: bootbox_text.message,
      locale: 'th',
      centerVertical: true,
      callback: (result) ->
        if (result)
          $.ajax({
            url: url
            method: ajax_param.method,
            dataType: ajax_param.dataType,
            beforeSend: (request) -> 
              request.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
            success: (data) ->
              if (data.error)
                bootbox.alert(data.error)
              else
                table.ajax.reload()
          })


export { ttu }

