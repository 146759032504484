// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

var $ = require('./jquery_custom.js')
var jquery = $;
var jQuery = $;

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// moment.js
window.moment = require('moment/moment-with-locales-dae')

//import select2
import 'select2'
import 'select2/dist/css/select2'
import 'select2-bootstrap-theme/dist/select2-bootstrap'
$.fn.select2.defaults.set( "theme", "bootstrap" );

//bootstrap
import './bootstrap_custom.js'
import bsCustomFileInput from 'bs-custom-file-input'
window.bsCustomFileInput = bsCustomFileInput

//datatable
import './datatable_custom.js'

//moment and datetime
require('tempusdominus-bootstrap-4/tempusdominus-bootstrap-4-dae.js')
require('tempusdominus-bootstrap-4/tempusdominus-bootstrap-4.css')
import './datetime'

var debounce = window.debounce = require ('debounce')

import { ttu } from './ttu_custom.js.coffee'
window.ttu = ttu

// define global.ttu_show_bootbox_confirm_delete
import './ttu_bootbox_helper.js'
// define global.ttu_init_datatable
import './ttu_datatable_helper.js'
// define global.ttu_init_select2
import './ttu_select2_helper.js'


//load main stylesheet
import '../stylesheets/application.scss'

//bootbox
window.bootbox = require('bootbox/bootbox.all')

//font awesome
import "@fortawesome/fontawesome-free/js/all";


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

