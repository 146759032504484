var $ = require('./jquery_custom')

// required for excel button
window.JSZip = require('jszip')

//please see https://stackoverflow.com/questions/57964095/rails-6-webpack-datatables-jquery
//in short, we need
//yarn add imports-loader
//and also prepend any file with imports-loader?define=>false!
require('imports-loader?define=>false!datatables.net')(window,$)
require('imports-loader?define=>false!datatables.net-bs4')(window,$)
require('imports-loader?define=>false!datatables.net-autofill')(window,$)
require('imports-loader?define=>false!datatables.net-autofill-bs4')(window,$)
require('imports-loader?define=>false!datatables.net-buttons')(window,$)
require('imports-loader?define=>false!datatables.net-buttons-bs4')(window,$)
require('imports-loader?define=>false!datatables.net-buttons/js/buttons.colVis')(window,$)
require('imports-loader?define=>false!datatables.net-buttons/js/buttons.html5')(window,$)
require('imports-loader?define=>false!datatables.net-buttons/js/buttons.print')(window,$)
require('imports-loader?define=>false!../../../vendor/datatables.net-editor')(window,$)
require('imports-loader?define=>false!../../../vendor/datatables.net-editor-bs4')(window,$)
require('imports-loader?define=>false!datatables.net-fixedcolumns')(window,$)
require('imports-loader?define=>false!datatables.net-fixedcolumns-bs4')(window,$)
require('imports-loader?define=>false!datatables.net-fixedheader')(window,$)
require('imports-loader?define=>false!datatables.net-fixedheader-bs4')(window,$)
require('imports-loader?define=>false!datatables.net-keytable')(window,$)
require('imports-loader?define=>false!datatables.net-keytable-bs4')(window,$)
require('imports-loader?define=>false!datatables.net-responsive')(window,$)
require('imports-loader?define=>false!datatables.net-responsive-bs4')(window,$)
require('imports-loader?define=>false!datatables.net-rowgroup')(window,$)
require('imports-loader?define=>false!datatables.net-rowgroup-bs4')(window,$)
require('imports-loader?define=>false!datatables.net-scroller')(window,$)
require('imports-loader?define=>false!datatables.net-scroller-bs4')(window,$)
require('imports-loader?define=>false!datatables.net-select')(window,$)
require('imports-loader?define=>false!datatables.net-select-bs4')(window,$)
require('imports-loader?define=>false!datatables.net-colreorder')(window,$)
require('imports-loader?define=>false!datatables.net-colreorder-bs4')(window,$)

//plugins
require('imports-loader?define=>false!./editor.select2.js')($,$.fn.DataTable)


//css
import 'datatables.net-bs4/css/dataTables.bootstrap4'
import 'datatables.net-autofill-bs4/css/autoFill.bootstrap4'
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4'
import '../../../vendor/datatables.net-editor-bs4/css/editor.bootstrap4'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4'
import 'datatables.net-responsive-bs4/css/responsive.bootstrap4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4'
import 'datatables.net-select-bs4/css/select.bootstrap4'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4'


